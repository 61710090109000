import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { eyeIcon, downloadIcon } from "@debionetwork/ui-icons";
import { queryGeneticAnalysisOrderByCustomerId, queryGeneticAnalystByAccountId, queryGeneticAnalystServicesByHashId, queryGeneticAnalysisByGeneticAnalysisTrackingId } from "@debionetwork/polkadot-provider";
import { downloadFile, decryptFile, downloadDocumentFile } from "@/common/lib/pinata-proxy";
import Kilt from "@kiltprotocol/sdk-js";
import { u8aToHex } from "@polkadot/util";
import CryptoJS from "crypto-js";
export default {
  name: "GeneticAnalysisList",
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      mnemonicData: state => state.substrate.mnemonicData
    })
  },
  data: () => ({
    eyeIcon,
    downloadIcon,
    headers: [{
      text: "Service Name",
      value: "serviceName",
      sortable: true
    }, {
      text: "Analyst Name",
      value: "analystName",
      sortable: false
    }, {
      text: "Order Date",
      value: "createdAt",
      sortable: true
    }, {
      text: "Last Update",
      value: "updatedAt",
      sortable: true
    }, {
      text: "Status",
      value: "status",
      sortable: true
    }, {
      text: "Action",
      value: "actions",
      sortable: false
    }],
    items: [],
    iconShow: true,
    //temporary disable.
    iconDownloadShow: false,
    publicKey: null,
    secretKey: null,
    loadingData: false
  }),
  watch: {
    mnemonicData(val) {
      if (val) this.initialData();
    },
    async lastEventData(val) {
      if (val !== null) {
        if (val.section === "geneticAnalysisOrders") {
          await this.fetchGeneticAnalysisData();
        }
      }
    }
  },
  async mounted() {
    await this.fetchGeneticAnalysisData();
  },
  async created() {
    if (this.mnemonicData) this.initialData();
  },
  methods: {
    async initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    },
    async fetchGeneticAnalysisData() {
      this.loadingData = true;
      try {
        this.items = [];
        const orderList = await queryGeneticAnalysisOrderByCustomerId(this.api, this.wallet.address);
        const paidOrder = [];
        orderList.forEach(order => {
          const status = order.status;
          if (status === "Paid" || status === "Refunded" || status === "Fulfilled") {
            paidOrder.push(order);
          }
        });
        paidOrder.forEach(async order => {
          const {
            geneticAnalysisTrackingId,
            sellerId,
            serviceId,
            id,
            createdAt,
            updatedAt
          } = order;
          const geneticAnalysis = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, geneticAnalysisTrackingId);
          const analystInfo = await queryGeneticAnalystByAccountId(this.api, sellerId);
          const geneticAnalysisService = await queryGeneticAnalystServicesByHashId(this.api, serviceId);
          const timestamp = geneticAnalysis.createdAt;
          const data = {
            trackingId: geneticAnalysisTrackingId,
            orderId: id,
            serviceName: geneticAnalysisService.info.name,
            analystName: "".concat(analystInfo.info.firstName, " ").concat(analystInfo.info.lastName),
            analystInfo,
            createdAt: this.formatDate(createdAt),
            updatedAt: this.formatDate(updatedAt),
            status: this.getStatus(geneticAnalysis.status),
            ipfsLink: geneticAnalysis.reportLink,
            timestamp
          };
          this.items.push(data);
        });
        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;
      }
    },
    toDetail(item) {
      this.$router.push({
        name: "customer-genetic-analysis-detail",
        params: {
          id: item.orderId
        }
      });
    },
    formatDate(date) {
      const formattedDate = new Date(parseInt(date.replace(/,/g, ""))).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
      return formattedDate;
    },
    getStatus(status) {
      if (status === "Registered") {
        return "Open";
      }
      if (status === "InProgress") {
        return "In Progress";
      }
      if (status === "ResultReady") {
        return "Done";
      }
      return "Rejected";
    },
    async toDownload(item) {
      const pair = {
        publicKey: item.analystInfo.info.boxPublicKey,
        secretKey: this.secretKey
      };
      const type = "application/pdf";
      const {
        data
      } = await downloadFile(item.ipfsLink);
      const decryptedFile = decryptFile(data, pair, type);
      await downloadDocumentFile(decryptedFile, item.ipfsLink.split("/").pop(), type);
    }
  }
};